<template>
  <div class="overall-page" :style="`background-image:url(${backgroundImg.background});min-height: 45.9375rem;`">
    <!-- 背景 -->
    <div class="login">
      <div class="logo">
        <!-- logo -->
        <img :src="backgroundImg.logoPicture" />
        <!-- logo名称 -->
        <span>{{ backgroundImg.systemName }}</span>
      </div>
      <div class="login-body">
        <img class="illustration" :src="backgroundImg.picture" />
        <div class="line"></div>
        <div class="login-info" style="z-index: 10">
          <!-- 系统名称 -->
          <div class="title">{{ backgroundImg.logoName }}</div>
          <span>{{ backgroundImg.version }}</span>
          <!-- <div class="flex-c login-type-tag m-b-30">
            <el-button class="m-r-16" type="warning" size="mini" @click="newLoginType='1'">验证码</el-button>
            <el-button type="warning" size="mini" @click="newLoginType='2'">密码</el-button>
          </div> -->
          <el-menu class="m-b-60 login-type-tag" :default-active="newLoginType" mode="horizontal" @select="handleSelectLogin" text-color="#545c64"
                   active-text-color="#ffd04b">
            <el-menu-item index="1">短信验证</el-menu-item>
            <el-menu-item index="2">密码登录</el-menu-item>
          </el-menu>
          <!-- 手机号 -->
          <div v-if="newLoginType==1" class="phone-number">
            <el-input v-model="phone" placeholder="请输入电话" @input="
                (phone = phone.replace(/[^\d]/g, '')), (loginTipShow = true)
              " maxlength="11" class="input-phone"></el-input>
            <img class="account-number" src="../../assets/img/login/tou.png" />
            <div @click="getCaptcha" class="verification-code-number">
              {{ show ? "获取验证码" : number + "S" }}
            </div>
          </div>
          <!-- 验证码 -->
          <div v-if="newLoginType==1">
            <el-input v-model="captcha" placeholder="请输入手机验证码" maxlength="11" @input="loginTipShow = true" class="input-phone"></el-input>
            <img class="account-number" src="../../assets/img/login/suo.png" />
          </div>
          <!-- 用户名 -->
          <div v-if="newLoginType==2" class="phone-number">
            <el-input v-model="phone" placeholder="请输入用户名" @input="
                (phone = phone.replace(/[^\d]/g, '')), (loginTipShow = true)
              " maxlength="11" class="input-phone"></el-input>
            <img class="account-number" src="../../assets/img/login/tou.png" />
          </div>
          <!-- 密码 -->
          <div v-if="newLoginType==2">
            <el-input v-model="password" type="password" placeholder="请输入密码" maxlength="11" @input="loginTipShow = true" class="input-phone"></el-input>
            <img class="account-number" src="../../assets/img/login/suo.png" />
          </div>
          <!-- ----------------------------- -->
          <!-- 微信二维码 -->
          <!-- <div class="wx-code" v-show="loginMethod('0') && !loginShow"> -->
          <!-- <img  class="wx-code-img" :src="wxCodeUrl"  /> -->
          <!-- <div id="wx_Code"></div>
          </div> -->

          <!-- 手机号 -->
          <!-- <div v-if="loginMethod('2') && loginShow" class="phone-number">
            <el-input v-model="phone" placeholder="请输入电话" @input="
                (phone = phone.replace(/[^\d]/g, '')), (loginTipShow = true)
              " maxlength="11" class="input-phone"></el-input>
            <img class="account-number" src="../../assets/img/login/tou.png" />
            <div @click="getCaptcha" class="verification-code-number">
              {{ show ? "获取验证码" : number + "S" }}
            </div>
          </div> -->
          <!-- 验证码 -->
          <!-- <div v-if="loginMethod('2') && loginShow">
            <el-input v-model="captcha" placeholder="请输入手机验证码" maxlength="11" @input="loginTipShow = true" class="input-phone"></el-input>
            <img class="account-number" src="../../assets/img/login/suo.png" />
          </div> -->
          <!-- 用户名 -->
          <!-- <div v-if="loginMethod('3') && loginShow" class="phone-number">
            <el-input v-model="phone" placeholder="请输入用户名" @input="
                (phone = phone.replace(/[^\d]/g, '')), (loginTipShow = true)
              " maxlength="11" class="input-phone"></el-input>
            <img class="account-number" src="../../assets/img/login/tou.png" />
          </div> -->
          <!-- 密码 -->
          <!-- <div v-if="loginMethod('3') && loginShow">
            <el-input v-model="password" type="password" placeholder="请输入密码" maxlength="11" @input="loginTipShow = true" class="input-phone"></el-input>
            <img class="account-number" src="../../assets/img/login/suo.png" />
          </div> -->
          <div class="tips">
            <img v-if="!loginTipShow" src="../../assets/img/cutGraph/loginTip.png" />
            <div v-if="!loginTipShow&&!messageType">您输入的信息有误，请重新输入!</div>
            <div v-if="!loginTipShow&&messageType">{{messageName}}</div>
          </div>
          <!-- <img @click="login" v-if="loginShow" class="login-img" src="../../assets/img/gjh/btnLogin.png" alt="" /> -->
          <img @click="newLogin(newLoginType)" class="login-img" src="../../assets/img/gjh/btnLogin.png" alt="" />
        </div>
      </div>
      <div class="tip">
        康奈网络提供技术支持www.yuqing.pro 陕ICP备14007110号
      </div>
    </div>
  </div>
</template>
<script src="http://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js"></script>
<script>
!(function (a, b, c) {
  function d(a) {
    var c = "default";
    a.self_redirect === !0
      ? (c = "true")
      : a.self_redirect === !1 && (c = "false");
    var d = b.createElement("iframe"),
      e =
        "https://open.weixin.qq.com/connect/qrconnect?appid=" +
        a.appid +
        "&scope=" +
        a.scope +
        "&redirect_uri=" +
        a.redirect_uri +
        "&state=" +
        a.state +
        "&login_type=jssdk&self_redirect=" +
        c +
        "&styletype=" +
        (a.styletype || "") +
        "&sizetype=" +
        (a.sizetype || "") +
        "&bgcolor=" +
        (a.bgcolor || "") +
        "&rst=" +
        (a.rst || "");
    (e += a.style ? "&style=" + a.style : ""),
      (e += a.href ? "&href=" + a.href : ""),
      (d.src = e),
      (d.frameBorder = "0"),
      (d.allowTransparency = "true"),
      (d.sandbox = "allow-scripts allow-top-navigation allow-same-origin"),
      (d.scrolling = "no"),
      (d.width = "300px"),
      (d.height = "400px");
    var f = b.getElementById(a.id);
    if (f) f.innerHTML = "";
    if (f) f.appendChild(d);
  }
  a.WxLogin = d;
})(window, document);
import {
  getLoginJzMsg,
  loginBackground,
  combinationLogin,
  getMatMenuList,
} from "../../api/login";
import { baseInfo } from "../../api/login/baseInfo";
import WX from "@/api/wxLogin/index.js";
import urlencode from "urlencode";
import { peizhi } from '../../api/peizhi';
import { Dictionaries } from "../../api/platformaccount";
import { mapMutations } from 'vuex';
export default {
  data() {
    return {
      newLoginType: '1',//新的登录方式  1:短信验证2:密码登录
      loading: false,
      url: "",
      show: true,
      unionid: "",
      loginShow: false, //登陆方式显示
      // userName:'',//用户名/
      password: "", //密码
      phone: "", //手机号/用户名
      captcha: "", //验证码
      number: 120,
      loginTipShow: true, //登录信息是否正确 
      messageType: false,
      messageName: '',
      userInfo: {}, //用户信息
      backgroundImg: {}, //背景图片
      style: "", //二维码样式
      clientWidth: document.documentElement.clientWidth,
      state: "",
      domain: peizhi().domain
      // domain: "apanage.konne.cn", // 本地用 || 118用
      // domain: "sd.wangxinban.cn",  // 线上地址用  ||  网信办
      // domain:window.location.host  //打包的时候用这个
    };
  },
  mounted() {
    localStorage.clear();
    sessionStorage.clear();
    window.addEventListener("keydown", this.keyupSubmit); //监听用户回车事件
    this.background();
    this.clientWidth = document.documentElement.clientWidth;
    this.windowSize();
    if (window.location.href.includes("code")) {
      //是否扫码
      this.wcCallBack();
    }
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyupSubmit, false); //销毁回车事件，如果不销毁，在其他页面敲回车也会执行回车登录操作。
  },
  methods: {
    ...mapMutations(['setUser']),
    // 新的登录按钮事件
    newLogin() {
            console.log(this.newLoginType);
      if (this.newLoginType=='1'){
        this.mobileLogin();//验证码
      }else if(this.newLoginType=='2'){
        this.passwordLogin();//密码
      }

      // this.newLoginType == '1' ? this.mobileLogin() : this.passwordLogin()
    },
     //手机验证码登陆
    async mobileLogin() {

      if (/^1[3-9]\d{9}$/.test(this.phone)) {
        //手机号校验
        if (this.captcha) {
          this.combinationLogin();
        } else {
          this.$message.warning("请输入验证码");
        }
      } else {
        this.$message.warning("请输入正确的手机号码");
      }
    },
    //账号密码登陆
    async passwordLogin() {

      if (/^1[3-9]\d{9}$/.test(this.phone)) {
        //手机号校验
        if (this.password) {
          console.log('进的是密码')
          this.combinationLogin();
        } else {
          this.$message.warning("请输入密码");
        }
      } else {
        this.$message.warning("请输入正确的用户名");
      }
    },
      //登陆
    async combinationLogin() {
      // window.localStorage.setItem('token','zqhrLjqkD1auerISnHIeVIRaNNHvEAOF')

      //  this.baseInfo() // 获取机构信息 || 已到期 || 快到期
      //  this.getMatMenuList() // 获取菜单 && 进入首页
      //  return
      let data = {
        phone: this.phone,
        code: this.captcha,
        passW: this.password,
        unionid: this.unionid,
        domain: this.domain, //chuanbo.yuqing.cn
        // domain:window.location.host
      };
      if(this.newLoginType=='1'){
        data.passW ='';
        data.unionid = ""
      }
      if(this.newLoginType=='2') {
        data.code ='';
        data.unionid = ""
      }
      let res = await combinationLogin(data);
      if (res.data.code == 200) {
        this.userInfo = res.data.data;
        window.localStorage.setItem("Refresh", true);
        window.localStorage.setItem("user", JSON.stringify(this.userInfo)),
        window.localStorage.setItem("token", this.userInfo.token);
        this.setUser(this.userInfo);
        this.baseInfo(res.data.data.roleId); // 获取机构信息 || 已到期 || 快到期
        // this.getMatMenuList(res.data.data.roleId); // 获取菜单 && 进入首页
      } else {
        if (res.data.code == 500) {
          this.messageType = true
          this.messageName = res.data.msg
        }
        if (this.loginShow) {
          //非二维码登陆显示错误提示
          this.loginTipShow = false;
        }
      }
    },
    // 新的选择登录方式
    handleSelectLogin(key) {
      console.log(key);
      this.newLoginType = key
      this.loginTipShow = true
    },
    keyupSubmit(e) {
      if (e.keyCode == 13) {
        this.newLogin();
      }
    },
    //窗口大小
    windowSize() {
      setInterval(() => {
        let width = document.documentElement.clientWidth;
        if (this.clientWidth != width && width >= 1366) {
          this.clientWidth = width;
          this.getcss();
        }
      }, 500);
    },
    //二维码样式
    getcss() {
      let width = (0.141 * document.documentElement.clientWidth).toFixed("0");
      this.style = btoa(
        `.impowerBox .qrcode {width: ${width}px;margin:0px}.impowerBox .title {display: none;}.impowerBox .info {width: 200px;display: none;}.status_icon {display: none}.impowerBox .status {display:none;text-align: center;}`
      );
      this.getcode();
    },
    //获取二维码
    getcode() {
      let str = "";
      let arr = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
      ];
      for (let i = 0; i < 32; i++) {
        str += arr[Math.round(Math.random() * 14)];
      }
      let domain = this.domain || window.location.host;
      // let domain = "chuanbo.yuqing.cn"
      var obj = new WxLogin({
        self_redirect: false,
        id: "wx_Code", //需要承载二维码的容器id
        appid: "wxdc4d77ae3e0e18e6",
        scope: "snsapi_login", //网页授权，目前网页只有这一种方式，静默授权
        redirect_uri: urlencode(
          `http://guanli.konne.cn/wechat.html?path=http://${domain}/#/login`
        ), //回调域名
        state: str, //Math.random(),
        style: "black",
        href: `data:text/css;base64,${this.style}`,
      });
    },
    async wcCallBack() {
      let list = window.location.href.split("?");
      let code = list[1].split("&")[0].split("=")[1];
      this.state = list[1].split("&")[1].split("=")[1];
      let data = {
        code: code,
        state: this.state,
      };
      let res = await WX.wcCallBack(data);
      if (res.data.code == 200) {
        this.getUnionid();
      } else if (res.data.code == 500) {
        this.$message.warning("获取用户信息失败,请重新扫码");
      }
    },
    //获取unionid
    async getUnionid() {
      let res = await WX.getUid({ state: this.state });
      if (res.data.code == 200) {
        this.unionid = res.data.data.unionid;
        if (window.localStorage.getItem("loginType") == "0") {
          //只有扫码登陆
          this.combinationLogin();
        } else {
          this.loginShow = true;
        }
      } else if (res.data.code == 500) {
        this.$message.warning(res.data.msg);
      }
    },
  
    // 获取机构信息 || 已到期 || 快到期
    async baseInfo(roleId) {
      const Res = await baseInfo();
      window.localStorage.setItem("prod", Res?.data?.data ? JSON.stringify(Res.data.data) : '');

      this.getMatMenuList(roleId); // 获取菜单 && 进入首页
    },
    //获取背景
    async background() {
      let res = await loginBackground({ requestDomain: btoa(this.domain) });
      // let res  = await Login.loginBackground({requestDomain:btoa(window.location.host)})
      if (res.data.code == 200) {
        this.backgroundImg = res.data.data;
        if (!res.data.data.loginMethod.includes("0")) {
          //无二维码登录时显示其他登陆
          this.loginShow = true;
        }
        //
        window.localStorage.setItem("loginType", res.data.data.loginMethod); //登录方式
        window.localStorage.setItem("systemName", res.data.data.systemName); //系统名称
        document.title = res.data.data.systemName; //浏览器标题
        setTimeout(() => {
          this.getcss();
        }, 20);
      } else {
        this.$message.warning(res.data.msg);
      }
    },
    //显示登录方式
    loginMethod(val) {
      let arr = [];
      if (window.localStorage.getItem("loginType")) {
        arr = window.localStorage.getItem("loginType").split(",");
        // arr = ['3'];
      }
      if (arr.indexOf(val) == -1) {
        return false;
      } else {
        return true;
      }
    },
    //登陆按钮
    // login() {
    //   if (this.loginShow) {
    //     window.localStorage
    //       .getItem("loginType")
    //       .split(",")
    //       .forEach((e) => {
    //         if (e == "2") {
    //           //手机号验证登陆
    //           this.mobileLogin();
    //         }
    //         if (e == "3") {
    //           //账号密码登陆
    //           this.passwordLogin();
    //         }
    //       });
    //   }
    // },
   
    //菜单
    async getMatMenuList(roleId) {
      let res = await getMatMenuList();
      if (res.data.code == 200) {
        if (res.data.data) {
          let data = res.data.data || [];
          // 机构普通用户
          // if (roleId !== 7) {
          //   // 1000表示是系统设置
          //   data = data.filter((v) => v.id !== 1000);
          // }
          // 1100 大系统研判  1200 我的收藏
          data = data.filter((v) => v.id !== 1100)
          data = data.filter((v) => v.id !== 1200);
          data.forEach((e) => {
            e.isSelect = false;
          });
          data.forEach((e) => {
            e.icon1 = e.icon.split(",")[1];
            e.icon2 = e.icon.split(",")[0];
          });
          window.localStorage.setItem("menu", JSON.stringify(data)); //缓存菜单
          const todata = (data) =>data.map((item) => ({ label: item.label, value: item.value }));
          let _self = this;
          Promise.all([Dictionaries("platform_type"),Dictionaries("body_type"),Dictionaries("message_type"),Dictionaries("is_authenticate")]).then(res=>{
            let datatype = res.map(item=>item.data.data)
            window.localStorage.setItem("platform_type", JSON.stringify(todata(datatype[0] || [])));
            window.localStorage.setItem("body_type", JSON.stringify(todata(datatype[1] || [])));
            window.localStorage.setItem("message_type", JSON.stringify(todata(datatype[2] || [])));
            window.localStorage.setItem("is_authenticate", JSON.stringify(todata(datatype[3] || [])));
            _self.$router.push({
              path: data[0].path, //跳转菜单列表第一个
            });
            window.localStorage.setItem("path", data[0].path); //缓存当前菜单路由
          })
        } else {
          //没有菜单权限
          this.$message.warning("您暂无权限访问");
        }
      } else {
        this.$message.error(res.data.msg);
      }
    },
    //获取验证码
    async getCaptcha() {
      if (/^1[3-9]\d{9}$/.test(this.phone)) {
        let res = await getLoginJzMsg({ phone: this.phone });
        if (res.data.code == 200) {
          this.show = false;

          this.time();
        } else {
          this.$message.warning(res.data.msg);
        }
      } else {
        this.$message.warning("请输入正确的手机号码");
      }
    },

    //计时
    time() {
      if (this.number != 0) {
        setTimeout(() => {
          this.number--;
          this.time();
        }, 1000);
      } else {
        this.number = 120;
        this.show = true;
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "fz";
  // src: url("../../assets/font/FZZCHJW.otf");
  src: url("../../assets/font/FZZCHJW.otf");
  font-weight: normal;
  font-style: normal;
}
.overall-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  // background-image: url('../../assets/img/login/loginBG2.png');
  // -webkit-user-select: none; /*谷歌 /Chrome*/
  // -moz-user-select: none; /*火狐/Firefox*/
  // -ms-user-select: none; /*IE 10+*/
  // user-select: none;
  .background {
    width: 100%;
    height: 100%;
    // min-height: 920px;
    // min-width: 1920px;
    position: absolute;
    z-index: 0;
    top: 0;
  }
  .tips {
    display: flex;
    align-items: center;
    height: 22px;
    width: 340px;
    margin-top: 25px;
    div {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ff3000;
      margin-left: 19px;
    }
  }
  .login {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1600px;
    height: 793px;
    background: #ffffff;
    border-radius: 20px;
    z-index: 10;
    .logo {
      display: flex;
      align-items: center;
      height: 54px;
      width: calc(100% - 78px);
      margin: 48px 0px 39px 78px;
      img {
        width: 134px;
        height: 56px;
      }
      span {
        font-size: 30px;
        font-family: "fz";
        font-weight: 400;
        color: #043053;
        margin-left: 12px;
      }
    }
    .login-body {
      display: flex;
      align-items: center;
      width: 100%;
      .illustration {
        height: 618px;
        width: 816px;
        margin: 0px 65px 0px 81px;
      }
      .line {
        width: 1px;
        height: 580px;
        background: #c7cddf;
      }
      .login-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 617px;
        height: 600px;
        .title {
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #3a405b;
          margin: 60px 0px 13px 0px;
        }
        span {
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #3a405b;
          margin-bottom: 20px;
        }
        .login-type-tag {
          .el-menu-item {
            padding: 0 50px;
          }
        }
        .phone-number {
          margin-bottom: 50px;
        }
        .input-phone {
          width: 340px;
        }
        .account-number {
          position: absolute;
          margin-top: 9px;
          margin-left: -340px;
          height: 22px;
          width: 22px;
        }
        .verification-code-number {
          width: 80px;
          white-space: nowrap;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: nowrap;
          font-size: 16px;
          height: 36px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #f4630b;
          cursor: pointer;
          position: absolute;
          margin-top: -36px;
          margin-left: 259px;
          border-radius: 1px;
        }
        .login-img {
          margin-top: 33px;
          cursor: pointer;
          width: 360px;
          height: 60px;
        }
      }
    }
  }
  .tip {
    // width: 100%;
    font-size: 12px;
    white-space: nowrap;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    position: absolute;
    margin-top: 820px;
  }
  ::v-deep .el-input__inner {
    border: 0;
    border-radius: 0;
    border-bottom: 2px solid #d6d6d6;
    padding-left: 41px;
  }
  .wx-code {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 270px;
    width: 100%;
    // border: 1px solid #C7CDDF;
    .wx-code-img {
      height: 236px;
      width: 236px;
    }
    #wx_Code {
      display: flex;
      justify-content: center;
      height: 270px;
      width: 100%;
      // ::v-deep iframe{
      //   height: 270px;
      // }
    }
  }
}
</style>
